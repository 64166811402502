
.thumb-container {
    /* position: relative; */
    border: 0.12vw solid transparent;
    /* padding: 2px; */
    transition: all 0.2s ease;
  }
  
  .thumb-container:hover {
    border-color: #fff;
  }
  
  @media (min-width: 14in) and (max-width: 24in){
    .game-show-carousel {
      position: relative;
      max-width: 45vw;
      padding-bottom: 0.5vw;
      padding-left: 0.1vw;
      padding-right: 0.1vw;
      height: 26.5vw;
      bottom: 2.95vw;
    }
  
    .game-show-carousel-thumbs {
      max-width: 45vw;
      height: 5.5vw;
      bottom: 6vw;
      padding-top: 0.1vw;
      padding-left: 0.15vw;
      padding-right: 0.05vw;
      padding-bottom: 0.4vw;
    }
  
    .gameShowCarouselBox {
      position: relative;
      right: 21.8vw;
      /* margin-right: 22.8vw; */
    }
  
    .game-show-carousel-thumbs img {
      width: 10.5vw;
      height: 5.5vw;
    }

    .gameShowCarouselBox .swiper .main-carousel-img{
      width: 45vw;
      height: 24vw;
      /* margin-left: 5vw; */
    } 
    
  }
  
 
  