.gameNameShowPage{
    position: relative;
    bottom: 8vh;
    margin-left: 13.99vw;
    color: white;
    font-family: Motiva Sans, Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 32px;
    text-overflow: ellipsis;
}

.gameNavBarShowPage{
    position: relative;
    top: -0.1vh;
    padding-bottom: 3vh;
}

.allgameshowpageItems {
  display: flex;
  flex-direction: column;
}

.gamesShowInfoDes{
  position: relative;
  /* top: 45.5vh; */
  top: 2vw;
  left: 13.9vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  /* padding: 0 10rem; */
  text-overflow: ellipsis;
  /* max-width: 45%; */
  font-family: Arial, Helvetica, sans-serif;
  color: #a0a6ae;
  font-size: 16px;
  padding-bottom: 3vw;
  letter-spacing: 1px;
  line-height: 1.5;
}

.gameinfobuyBox{
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.showCart{
  position: relative;
  top: 7.4vw;
  left: 75.4vw;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(#799905 5%,#536904 95%);
  color: #d2efa9;
  border: none;
  width: 6vw;
  padding: 0.35vh;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Motiva Sans', sans-serif;
  border: solid 0.5px black;
  box-shadow: 0 0 2px #020000;;
  z-index: 3;
}

.showCart:hover{
  background: #d3e885;
  color: #f7fdf4;;
}
  
.showPageFooter{
  margin-top: 10vh;
}

.showPageMainBox{
  position: relative;
  display: flex;
  flex-direction: column;
  /* margin-right: 1vw; */
}

#reviewForm1{
  margin-top: 11.5vw;
  margin-left: 13.85vw;
}

.game-reviews{
  margin-left: 13.85vw;
}

.review-list h2::after {
  content: "";
  display: block;
  height: 2px;
  width: 50vw;
  background: linear-gradient(90deg, #3a6e8b 0, transparent);
  margin-top: 1vw;
}

