.homePageGameList{
    display: flex;
    position: relative;
    justify-content: center;
    margin-top: 3vw;
    /* margin-left: 18vw; */
}

.GameNavbarBox {
    align-items: center;
    max-width: 65.4vw;
    padding: 3px;
    padding-right: 2.25vw;
    background: linear-gradient(
      90deg,
      rgba(108, 174, 249, 0.9) 10%,
      rgba(47, 155, 255, 0.8) 25%,
      #0f216e
    );
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: space-between;
    border-radius: 1px;
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.1);
    border: solid 0.5px black;
    margin-bottom: 4.8vw;
  }
  
  @media (min-width: 14in) {
    .GameNavbarBox {
      margin-top: 7.5vw;
      margin-left: 14vw;
    }
  }

  /* .homePageIndexContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2vw;
  } */
  
/* 
.homePageGameList:focus {
    outline: none;
} */
  .indexCart{
    position: relative;
    top: 7.4vw;
    left: 75.45vw;
    font-size: 14px;
    text-align: center;
    background: linear-gradient(#799905 5%,#536904 95%);
    color: #d2efa9;
    border: none;
    width: 6vw;
    padding: 0.35vh;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Motiva Sans', sans-serif;
    border: solid 0.5px black;
    box-shadow: 0 0 2px #020000;;
  }

  .indexCart:hover{
    background: #c5d0a1d4;
    text-shadow: #020000;
    color: rgba(22, 21, 21, 0.834);;
  }

  .homepageFooter{
    margin-top: 30vh;
  }

  /* .homePageShowPageBox{
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

  .homePageIndexContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 18.5vw;
  }

  #GameNavBarHome{
    /* margin-right: 10vw; */
    margin-top: 1vw;
    margin-left: 21vw;
    width: 100vw;
  }
  #GameCarouselHome{
    margin-left: 35.1vw;
  }
