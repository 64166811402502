.GameShowInfoBox {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    text-overflow: ellipsis;
    max-width: 21.1vw;
    position: relative;
    top: 12.78vw;
    left: 5.34vw;
    background: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0.4) 90%);
    padding-bottom: 12vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11x;
    color: #c6d4df;
    line-height: 1.1;
    
  }
  
  .gamesGenreCat p{
    position: relative;
    top: 3.5vw;
    display: inline-block;
    line-height: 1.3vw;
    padding: 0 0.2vw;
    color: #67c1f5;
    background-color: rgba( 103, 193, 245, 0.23 );
    box-shadow: none;
    margin-right: 0.5vw;
    border-radius: 2px;
    cursor: not-allowed;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14.5px;
  }

  .gamesGenreCat p:hover{
    background: linear-gradient(120deg, #06BFFF 20%, #2D73FF 80%);
    color: white;
  }

  .gamesShowInfoDes h1{
    font-size: 20px;
  }
  
  .gamesShowInfoDes h1:after {
    content: "";
    display: block;
    height: 2px;
    width: 50vw;
    background: linear-gradient(90deg, #3a6e8b 0, transparent);
    margin-top: 1vw;
  }
  
  .gamesDevPubRe {
    display: flex;
    flex-direction: column;
    gap: 0.1vw;
  }
  
  .info-label-pub {
    font-size: 12px;
    font-weight: 200;
    color: #4a5a66;
    padding-right: 50px;
  }

  .info-label-dev {
    font-size: 12px;
    font-weight: 200;
    color: #4a5a66;
    padding-right: 44px;
  }

  .info-label-date {
    font-size: 12px;
    font-weight: 200;
    color: #4a5a66;
    padding-right: 27px;
  }
  
  .game-info-value {
    color: #60b4e3;
    font-size: 14.5px;
    cursor: not-allowed;
  }

  .game-info-value:hover{
    color: white;
  }

  .game-info-datval {
    color: #8f98a0;
    font-size: 14.5px;
  }
  
  @media (min-width: 14in) and (max-width: 24in) {
    .GameShowInfoBox {
      margin-left: 55.45vw;
      margin-top: -49vw;
    }
    .gamesShowInfoDes {
      max-width: 45vw;
    }
  }
  
 