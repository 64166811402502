.review {
    margin-bottom: 2vw;
    background-color: #16202d;
    color: white;
    padding-bottom: 2vw;
    width: 40vw;
  }
  
  .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #review-temp-img-container{
    margin-top: 0.5vw;
    margin-left: 1vw;
    display: flex;
    gap: 1vw;
  }

  #review-temp-img-container .review-author{
    margin-top: 0.5vw;
  }
  
  .review-author {
    font-weight: bold;
    color: #ccc7c77e;
    text-transform: capitalize;
  }
  
  .review-recommendation {
    margin-top: 0.5vw;
    width: 25vw;
    height: 1.8vw;
    padding-top: 0.8vw;
    color: #fff;
    background-color: #111a24;
  }
  
  .recommended .not-recommended {
    color: rgba(245, 245, 245, 0.887);
  }
  
  .review-body {
    margin-top: 2vw;
    margin-left: 15vw;
    line-height: 1.5;
    word-wrap: break-word;
    padding-right: 1vw;
  }
  
  .review button {
    margin-top: 2vw;
    padding: 0.3vw 1.8vw;
    border: none;
    border-radius: 4px;
    background-color: rgba(103,193,245,.2);
    color: #67c1f5;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .review-buttons-2{
    margin-left: 15vw;
  }
  
  .review button:hover {
    background: linear-gradient(120deg, #06BFFF 20%, #2D73FF 80%);
  }
  
  .review button:first-child {
    margin-right: 10px;
  }
  
  .temp-profile-pic2{
    width: 33px;
    height: 30px;
    border: solid 1px;
    box-shadow: 0 0 8px #d4cece;
  }

  .edit-icon2{
    margin-right: 0.2vw;
  }

  .delete-icon2{
    margin-right: 0.2vw;
  }

  .thumbs-up{
    position: relative;
    bottom: 0.7vw;
    padding: 0.33vw;
    margin-right: 1vw;
    font-size: 1.8vw;
    color: #62b8ea;
    background: linear-gradient(#355f7b,#234e6a);
  }

  .thumbs-down{
    position: relative;
    bottom: 0.7vw;
    padding: 0.33vw;
    color: #e06363;
    font-size: 1.8vw;
    margin-right: 1vw;
    background: linear-gradient(#64343a,#4d1d23)
  }
  
  .recommendation-text{
    position: relative;
    bottom: 1.5vw;
  }
