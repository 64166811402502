.navlink {
    display: flex;
    justify-content: end;
    gap: 0.5vw;
    padding-right: 3.2vw;
    margin-top: 2.4vh;
    font-size: 15px;
    z-index: 5;
    transition: all 0.3s ease-in;
}

.navlink a:hover {
    color: white;
}
  
.appName {
    position: absolute;
    color: #c5c3bf;
    padding-top: 2.4%;
    margin-left: 13.8vw;
    font-size: 28px;
    letter-spacing: 2px;
    font-family: "Motiva Sans", sans-serif;
}

.loginSignupLink{
    color: #b8b6b4;
    font-size: 1vw;
    padding: 1vw;
}

.loginSignupDivder{
    color: #b8b6b4;
    text-decoration: none;
    font-size: 1vw;
    padding: 1vw;
}

.supscript {
    font-size: 30%;
    color: #c5c3bf;
    position: relative;
    top: -1.8em;
  }
  

  /* --------------------------------- */
  .profileButtonWrapper {
    position: relative;
    right: 250px;
  }
  
  .profileButton {
    height: 24px;
    background: none;
    border: none;
    margin-right: 6px;
    color: #c5c3c2;
    font-size: 11.5px;
  }
  
  .profileButton:hover {
    cursor: pointer;
    color: white;
  }
  
  .profileButtonDropdown {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #171a21;
    width: 150px;
    height: 132px;
    padding: 6px 0 6px 0;
    box-shadow: 0 0 12px #000000;
    animation: fadein 2s;
    overflow: hidden;
    z-index: 3;
  }
  
  .profileButtonDropdown * {
    background: none;
    border: none;
    color: #b8b6b4;
    display: flex;
    align-items: center;
    height: 27px;
    text-decoration: none;
    font-size: 11.5px;
    padding: 5px 12px 5px 12px;
    margin: 8px 0;
    font-family: inherit;
  }
  
  .profileButtonDropdownItem{
    margin-bottom: 100px;
  }
  
  .profileButtonDropdown *:hover {
    cursor: pointer;
  }
  
  .profileButtonDropdownItem:hover {
    color: white;
    text-shadow: 0px 0px 1px #57cbde;
  }
  
  .logoutUserName{
    color: #57cbde;
  }
  
  .logoutUserName:hover{
    cursor: pointer;
    text-shadow: 0px 0px 1px #57cbde;
  }
  
/* ----------------------------------- */
.temp-profile-pic {
  position: relative;
  right: 160px;
  bottom: 25px;
  width: 33px;
  height: 30px;
  border: solid 1px;
  box-shadow: 0 0 8px #d4cece;
}

.stareamTitle{
  display: flex;
  gap: 8px;
}

.stareamTitle img{
  position: relative;
  bottom: 20px;
  max-width: 70px;
  height: auto;
}

.githubLinkedin-links{
  color: #b8b6b4;
  font-size: 17.5px;
  position: relative;
  top: 4.9vh;
  display: flex;
  gap: 2vw;
  margin-left: 32vw;
}

#gitHUBB:hover{
  color: white;
}

#linkedINN:hover{
  color: white;
}