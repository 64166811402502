.swiper {
  width: 67.69vw;
  /* height: 100%; */
  background-color: #101a23;
  border: solid 1.5px;
  box-shadow: 0 0 5px 3px rgba(255, 255, 255, 0.1);
  margin-right: 17.99vw;
}

.game-slide .game-images .game-image img {
  max-width: 12vw;
  height: 12vh;
  position: relative;
}

.game-image {
  position: relative;
  left: 0.55vw;
  width: 10vw;
  height: 14vh;
  margin-right: 3.2vw;
}

/* .game-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.game-slide {
  display: grid;
  /* padding-left: 1vw; */
  grid-template-columns: 0.8fr 1fr;
  align-items: center;
}

.game-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2vh;
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
  background-size: cover;
  width: 1.5vw;
  height: 0.5vh;
  /* margin-left: 15px;
  margin-right: 10px; */
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  top: 23vh;
  transform: translateY(-50%);
}

.swiper-button-prev {
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  z-index: 1;
}

.swiper-button-next {
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  z-index: 1;
}

.game-slide .main-image img {
  width: 38vw;
  height: 40vh;
  object-fit: cover;
}

.game-name-carousel {
  font-size: 20px;
  position: absolute;
  top: 1vw;
  right: 0;
  left: 0;
  bottom: 0;
  /* margin-top: 2vh;
  margin-left: 25vw; */
  width: 104.9vw;
  text-align: center;
  color: white;
}

.featured{
  position: absolute;
  top: 26.5vh;
  left: 15.4vw;
  font-family: "Motiva Sans", Sans-serif;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  /* margin: 0 0 10px; */
  letter-spacing: 0.03em;
  font-weight: normal;
}
