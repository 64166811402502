.homePageGameList {
  display: flex;
  flex-direction: column;
  width: 43vw;
  right: 3.4vw;
  padding-bottom: 8vw;
  /* position: relative; */
}

.homePageGameList .game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8.88vh;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: .5vh;
  padding: 0 0.5vw;
  transition: background-color 250ms;
  cursor: pointer;
  position: relative;
  width: calc(100% + 0.5vw);
}

.game-details {
  background-color: #95bcd3;
}


.game-details-wrapper {
  position: absolute;
  top: 0vh;
  right: -24.5vw;
  width: 21.2vw;
  height: 93.3vh;
  background-color: #23262d;
  z-index: 1;
  overflow: hidden;
}

.game-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 93.3vw;
  height: 93.3vh;
  padding: 0.3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 14px;
}

.game-details a:hover {
  text-decoration: underline;
}

.game.hover {
  background: linear-gradient( to right, #c6e6f8 5%,#95bcd3 95%);
  width: 45.3vw;
  /* padding-right: 10px; */
}

.game-details-wrapper h2 {
  padding-left: 1.04vw;
  text-transform: none;
  font-size: 21px;
  letter-spacing: 0.01vw;
  color: #263645;
  height: auto;
  max-width: 20vw; /* Adjust the width according to your design requirements */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.game-details-wrapper img {
  margin-top: -3vh;
  width: 18.8vw;
  height: 20vh;
  padding-left: 13px;
}

.game-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100vw;
}

.game-name {
  padding-top: 1vh;
  flex: 1;
  color: white;
  font-size: 1.2em;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  transition: color 0.25s;
}

.game-price {
  padding-top: 3vh;
  flex-shrink: 0;
  /* margin-left: 20px; */
  color: white;
  font-size: 16px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  transition: color 0.25s;
}

.game-mainImg {
  width: 6.3vw;
  height: 8.88vh;
  padding-right: 3vw;
}

.game-mainImg img {
  position: relative;
  right: 1vw;
  width: 9.5vw;
  height: 8.88vh;
  object-fit: cover;
}



