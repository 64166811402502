.edit-review-form {
  background-color: #16202d;
  color: white;
  padding: 2vw;
  margin-bottom: 2vw;
  border: none;
  width: 36vw;
}

.edit-review-form h3 {
  margin-bottom: 1vw;
}

.edit-review-form textarea {
  width: 90%;
  min-height: 8vw;
  padding: 1vw;
  margin-bottom: 2vw;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #fff;
  background-color: #111a24;
}

.review-recommendation-edit {
  margin-bottom: 2vw;
}

.review-recommendation-edit input[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.review-recommendation-edit input[type='radio']:checked + label {
  background: linear-gradient(120deg, #06BFFF 20%, #2D73FF 80%);
}

.review-recommendation-edit label {
  display: inline-block;
  padding: 0.4vw 1.5vw;
  border: none;
  border-radius: 4px;
  background-color: rgba(103,193,245,.2);
  color: #67c1f5;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 1vw;
}

.review-recommendation-edit input[type='radio']:checked + label {
  background: linear-gradient(120deg, #06BFFF 20%, #2D73FF 80%);
}

.review-recommendation-edit label:hover {
  background: linear-gradient(120deg, #06BFFF 20%, #2D73FF 80%);
}

.edit-review-form button {
  padding: 0.6vw 1.2vw;
  border: none;
  border-radius: 4px;
  background-color: rgba(103,193,245,.2);
  color: #67c1f5;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-review-form button:hover {
  background: linear-gradient(120deg, #06BFFF 20%, #2D73FF 80%);
}

.edit-review-form .save-changes-button {
  margin-right: 21.5vw;
}
