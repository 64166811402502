.GameSearch {
    position: relative;
    left: 14vw;
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .search-container {
    position: relative;
    width: 25vw;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown {
    background: linear-gradient(to bottom, #51658b, #43435d);
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 9;
    top: 2.6vh;
    right: 12.8vw;
    width: 28vw;
    border-bottom: 1px solid black;
  }
  
  
  .dropdown:empty {
    border: none;
  }
  
 .dropdown-row {
  display: flex;
  cursor: pointer;
  text-align: start;
  color: #c6d4df;
  border-top: 1px solid black;
  box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.1);
  padding: 0.3vw;
}
  
  .dropdown-row:hover, .dropdown-row img:hover{
    background-color: #284288;
  }

  .dropdown-row img{
    max-width: 8.5vw;
  }
  
  input {
    color: #f5f5f5;
    font-size: 14px;
    /* margin-top: 1px; */
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.1);
    font-family: "Motiva Sans", Sans-serif;
    font-style: italic;
    border: none;
    font-weight: 300;
    /* width: 180px; */
    background-color: #316282;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 0px rgba(255, 255, 255, 0.2);
    width: 11vw;
    /* height: 20px; */
    outline: none;
    line-height: 20px;
    padding: 0 0.5vw; 
    /* margin: 0; */
  }
  
  input::placeholder {
    color: #bbb3b3;
  }
  
  input:hover {
    border: 1px solid #5fa8d3;
  }
  
  .search-inner {
    display: flex;
  }
  
/* ------------------------------------------------------- */

.yourStore{
  margin-left: 1vw;
  display: flex;
  gap: 1vw;
  cursor: pointer;
}

.yourStore:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.yourStore p{
  font-weight: bold;
  font-size: 14px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 34px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}

.yourStoreProfile{
  /* margin-top: 6.5px;
  width: 18px;
  height: 17px; */
  border: solid 1px;
  box-shadow: 0 0 8px #d4cece;
}

